import React from "react";
import Footer from "../../../components/shared/Footer/Footer";
import Navigation from "../../../components/shared/Navigation/Navigation";
import AboutArea from "./AboutArea/AboutArea";
import BannerArea from "./BannerArea/BannerArea";
import ChoseArea from "./ChoseArea/ChoseArea";
import FactArea from "./FactArea/FactArea";
import HeroSection from "./HeroSection/HeroSection";
import PricingArea from "./PricingArea/PricingArea";
import Projects from "./Projects/Projects";
import ServiceArea from "./ServiceArea/ServiceArea";
import Testimonial from "./Testimonial/Testimonial";

const Home = () => {
  return (
    <>
      <Navigation />
      <HeroSection />
      <AboutArea />
      <BannerArea />
      <ServiceArea />
      <ChoseArea />
      <FactArea />
      <PricingArea />
      {/* <Testimonial /> */}
      {/* <Projects /> */}
      <Footer />
    </>
  );
};

export default Home;
