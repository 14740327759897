import React from "react";
import { Link } from "react-router-dom";

const ChoseArea = () => {
  return (
    <>
      <section className="tp-choose-area position-relative pt-120 pb-90">
        <div className="tp-choose-yellow-bg"></div>
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-xl-6 col-lg-8">
              <div className="tp-choose z-index">
                <div
                  className="tp-section-title-wrapper mb-105 wow fadeInUp"
                  data-wow-delay=".2s"
                >
                  <h5 className="tp-section-subtitle common-yellow-shape common-white-shape section__sm__title">
                    Why Choose VD Services?
                  </h5>
                  <h2 className="tp-section-title">
                    Expert Disability You Can Trust
                  </h2>
                </div>
                <div className="tp-choose-img mb-40 z-index d-block d-xl-none">
                  <img
                    src="assets/img/stockImages/trust.jpg"
                    alt="img not found"
                  />
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="tp-choose-timeline ml-15 mb-30">
                      <div
                        className="tp-choose-timeline-single mb-55 pl-35 wow fadeInUp"
                        data-wow-delay=".4s"
                      >
                        <h4 className="tp-choose-timeline-single-title mb-15 hover-theme-color">
                          <Link to="/servicesDetails">
                            Every Person <br />
                            is Special
                          </Link>
                        </h4>
                        <p>
                          We recognise the unique needs of each client,
                          providing personalized and empathetic care to enhance
                          every home and life.{" "}
                        </p>
                      </div>
                      <div
                        className="tp-choose-timeline-single mb-55 pl-35 wow fadeInUp"
                        data-wow-delay=".6s"
                      >
                        <h4 className="tp-choose-timeline-single-title mb-15 hover-theme-color">
                          <Link to="/servicesDetails">
                            24/4 Support for <br />
                            your loved ones
                          </Link>
                        </h4>
                        <p>
                          We'll always ensures you have a caring and responsive
                          team ready to assist with any services or needs,
                          anytime.{" "}
                        </p>
                      </div>
                      <div
                        className="tp-choose-timeline-single pl-35 wow fadeInUp"
                        data-wow-delay=".8s"
                      >
                        <h4 className="tp-choose-timeline-single-title mb-15 hover-theme-color">
                          <Link to="/servicesDetails">
                            More than a service <br />
                            we're invested in you
                          </Link>
                        </h4>
                        <p>
                          We offer flexible service costs, making comprehensive
                          home care accessible and affordable for everyone.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="tp-choose-timeline ml-15 mb-30">
                      <div
                        className="tp-choose-timeline-single mb-55 pl-35 wow fadeInUp"
                        data-wow-delay="1s"
                      >
                        <h4 className="tp-choose-timeline-single-title mb-15 hover-theme-color">
                          <Link to="/servicesDetails">
                            Need urgent care <br />
                            no problem!
                          </Link>
                        </h4>
                        <p>
                          Reach out to us anytime for our services, or simply
                          leave a message on our contact form. We're always
                          ready to consult and tailor our services to your needs{" "}
                        </p>
                      </div>
                      <div
                        className="tp-choose-timeline-single mb-55 pl-35 wow fadeInUp"
                        data-wow-delay="1.2s"
                      >
                        <h4 className="tp-choose-timeline-single-title mb-15 hover-theme-color">
                          <Link to="/servicesDetails">
                            Comprehensive Care Solutions
                            <br />
                            For Every Need
                          </Link>
                        </h4>
                        <p>
                          Explore our wide range of services, meticulously
                          designed to cater to diverse home and disability care
                          needs. Our expertise spans various forms of
                          disabilities, with a special focus on addressing
                          dietary challenges effectively{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-5 col-12">
              <div
                className="tp-choose-img mb-30 z-index d-none d-xl-block wow fadeInUp"
                data-wow-delay=".6s"
              >
                <img
                  src="assets/img/stockImages/trust.jpg"
                  alt="img not found"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ChoseArea;
