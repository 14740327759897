import React from "react";
import HomeServiceItem from "../../../../components/HomeServiceItem/HomeServiceItem";

const ServiceArea = () => {
  return (
    <>
      <section className="tp-services-area theme-dark-bg">
        <div className="tp-custom-container">
          <div className="tp-services-bg grey-bg pt-120 pb-90 z-index">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-8">
                  <div
                    className="tp-section-title-wrapper text-center mb-55 wow fadeInUp"
                    data-wow-delay=".1s"
                  >
                    <h5 className="tp-section-subtitle section__sm__title common-yellow-shape mb-25">
                      Our Popular Services
                    </h5>
                    <h2 className="tp-section-title">
                      Your Partner in Home <br /> and Care Excellence
                    </h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <HomeServiceItem
                  icon_name="pot"
                  title="Kitchen Cooking"
                  description="Crafting delicious, nutritious meals that cater to all dietary and disability requirements. We make dining a delightful experience for everyone"
                />

                <HomeServiceItem
                  icon_name="vacuum-cleaner"
                  title="Personal Care"
                  description="Addressing all your hygiene needs with sensitivity and expertise. We ensure a clean and dignified living environment, no matter the situation."
                />

                <HomeServiceItem
                  icon_name="house-cleaning"
                  title="Home Care"
                  description="Providing support for daily living with compassionate and skilled hands. From routine tasks to personal care, we're here to make every day easier."
                />

                <HomeServiceItem
                  icon_name="desk"
                  title="Administration"
                  description="Managing health appointments and essential life tasks when you need it most. We offer the helping hand you need for life's administrative challenges."
                />

                <HomeServiceItem
                  icon_name="cleaning"
                  title="Cleaning"
                  description="More than just traditional cleaning, we ensure your home is a safe, clean haven. Our meticulous approach enriches your living environment."
                />

                <HomeServiceItem
                  icon_name="window"
                  title="Maintenance"
                  description="Keeping your home in top condition, from minor repairs to regular upkeep. Our services are designed to maintain the comfort and safety of your living space."
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServiceArea;
