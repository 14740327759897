import React from 'react';
import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';

const AppointmentBreadcrumb = () => {
    return (
        <>
          <Breadcrumb title="Appointment" />  
        </>
    );
};

export default AppointmentBreadcrumb;