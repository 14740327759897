import React from "react";

const ContactMap = () => {
  return (
    <>
      <div className="tp-map-area pt-120 pb-115">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="tp-contact-map">
                <iframe
                  title="contact map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d25255.85275588433!2d144.78896635!3d-37.6966313!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad658ab291c9477%3A0x5045675218cd1b0!2sTaylors%20Lakes%20VIC%203038!5e0!3m2!1sen!2sau!4v1700389036187!5m2!1sen!2sau"
                  width="600"
                  height="450"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactMap;
