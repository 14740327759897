import React from "react";
import { Link } from "react-router-dom";
import {
  FaMapMarkerAlt,
  FaEnvelopeOpen,
  FaFacebookF,
  FaTicketAlt,
} from "react-icons/fa";
import { AiOutlineGoogle } from "react-icons/ai";
import { BsTwitter, BsInstagram } from "react-icons/bs";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <footer className="theme-dark-bg">
        <div className="tp-footer-area-two pt-80 pb-50">
          <div className="container">
            <div className="row dflex justify-content-center">
              <div className="col-lg-3 col-sm-6">
                <div
                  className="tp-footer-widget footer-col-1 mb-30 wow fadeInUp"
                  data-wow-delay=".3s"
                >
                  <div className="tp-footer-info">
                    <div className="tp-footer-info-logo mb-35">
                      <Link to="/">
                        <img
                          src="assets/img/logo/logo-VD2.png"
                          className="img-fluid"
                          alt="img not found"
                        />
                      </Link>
                    </div>
                    <h4 className="mb-15">
                      <a href="tel:0426069846">0426 069 846</a>
                    </h4>
                    <h6 className="mb-15">
                      {" "}
                      <i>
                        {" "}
                        <FaEnvelopeOpen />{" "}
                      </i>
                      <a href="mailto:VDServices.health@gmail.com">
                        VDServices.health@gmail.com
                      </a>
                    </h6>
                    <h6 className="mb-15">
                      {" "}
                      <i>
                        {" "}
                        <FaMapMarkerAlt />{" "}
                      </i>{" "}
                      Taylors Lakes, Melbourne 3038
                    </h6>
                    <h6 className="mb-15">
                      {" "}
                      <i>
                        {" "}
                        <FaTicketAlt />{" "}
                      </i>{" "}
                      ABN Registration: 70960932019
                    </h6>
                    <div className="tp-footer-info-social">
                      <a href="/">
                        <i>
                          <FaFacebookF className="icon" />{" "}
                        </i>
                      </a>
                      <a href="/">
                        <i>
                          <BsTwitter className="icon" />{" "}
                        </i>
                      </a>
                      <a href="/">
                        <i>
                          <BsInstagram className="icon" />{" "}
                        </i>
                      </a>
                      <a href="/">
                        <i>
                          <AiOutlineGoogle className="icon" />{" "}
                        </i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div
                  className="tp-footer-widget footer-col-2 mb-30 wow fadeInUp"
                  data-wow-delay=".6s"
                >
                  <h4 className="tp-footer-widget-title mb-35">Our Services</h4>
                  <ul>
                    <li>
                      <a href="/servicesDetails">Kitchen Cooking</a>
                    </li>
                    <li>
                      <a href="servicesDetails">Personal Care</a>
                    </li>
                    <li>
                      <a href="servicesDetails">Home Care</a>
                    </li>
                    <li>
                      <a href="servicesDetails">Administration</a>
                    </li>
                    <li>
                      <a href="servicesDetails">Maintenance</a>
                    </li>
                    <li>
                      <a href="servicesDetails">Cleaning</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tp-copyright-area-two bg-green-light z-index pt-30 pb-30">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="tp-copyright tp-copyright-two text-center">
                  <p className="m-0">
                    Vicky's Disability Services | Copyright &copy;{currentYear}.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
