import React from "react";
import HomeFactSingleItem from "../../../../components/HomeFactSingleItem/HomeFactSingleItem";

const FactArea = () => {
  return (
    <>
      <section className="tp-fact-area theme-dark-bg pt-75 pb-20">
        <div className="container">
          <div className="row">
            <HomeFactSingleItem
              icon="shuttle"
              fact_num="35"
              title="People Serviced"
            />
            <HomeFactSingleItem
              icon="broom"
              fact_num="74"
              title=" Homes Cleaned"
            />
            <HomeFactSingleItem
              icon="selection"
              fact_num="115"
              title="Families Reached"
            />
            <HomeFactSingleItem
              icon="badge"
              fact_num="450+"
              title="Meals Cooked"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default FactArea;
