import React from "react";
import { Link } from "react-router-dom";

const AboutArea = () => {
  return (
    <>
      <section className="tp-about-area position-relative pt-120 pb-90 fix">
        <div className="tp-about-shape"></div>
        <div className="container">
          <div className="row justify-content-xl-between justify-content-md-center">
            <div className="col-xl-5 col-12">
              <div
                className="tp-about-img z-index wow fadeInUp"
                data-wow-delay=".3s"
              >
                <img
                  src="assets/img/stockImages/cooking.jpg"
                  alt="img not found"
                />
              </div>
            </div>
            <div className="col-xl-6 col-md-10">
              <div
                className="tp-about-text z-index wow fadeInUp"
                data-wow-delay=".6s"
              >
                <div className="section-title-wrapper mb-30">
                  <h5 className="tp-section-subtitle section__sm__title common-yellow-shape mb-20">
                    Compassionate Care, Everyday
                  </h5>
                  <h2 className="tp-section-title">
                    Nurturing Your Loved <br />
                    Ones with Heartfelt Support
                  </h2>
                </div>
                <p className="mb-40">
                  At VD Services, we go beyond traditional care. Our dedicated
                  team offers comprehensive support, from household tasks to
                  personalized care, ensuring comfort and joy in every aspect of
                  daily living. We're here to make life easier and brighter for
                  your family.
                </p>
                <div className="row mb-10">
                  <div className="col-sm-6">
                    <div className="tp-about-service mb-30">
                      <div className="tp-about-service-icon yellow-circle-shape mb-15">
                        <i className="flaticon-snowfall"></i>
                      </div>
                      <div className="tp-about-service-text">
                        <h4 className="tp-about-service-text-title mb-15 hover-theme-color">
                          <Link to="/services">Tailored Residential Care</Link>
                        </h4>
                        <p>
                          Our services are designed to embrace all needs,
                          focusing on providing a loving, inclusive environment
                          for individuals with diverse abilities.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="tp-about-service mb-30">
                      <div className="tp-about-service-icon yellow-circle-shape mb-15">
                        <i className="flaticon-business-and-trade"></i>
                      </div>
                      <div className="tp-about-service-text">
                        <h4 className="tp-about-service-text-title mb-15 hover-theme-color">
                          <Link to="/services">Comprehensive Home Support</Link>
                        </h4>
                        <p>
                          Whether it's a cozy apartment or a larger residence,
                          our team is equipped to provide top-notch care and
                          assistance, tailored to every unique need.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="tp-about-author mb-30">
                      <div className="tp-about-author-img">
                        <img
                          src="assets/img/stockImages/Vicky.jpg"
                          className="img-fluid"
                          alt="img not found"
                        />
                      </div>
                      <div className="tp-about-author-text">
                        <h4 className="tp-about-author-text-title">
                          Vicki Pham
                        </h4>
                        <span>Chief Services Officer</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="tp-about-number mb-30">
                      <div className="tp-about-number-icon">
                        <i className="flaticon-phone-call-1"></i>
                      </div>
                      <div className="tp-about-number-text">
                        <span>
                          Always Here for You <br></br>24/7 Support
                        </span>
                        <a href="tel:0426069846">0426 069 846</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutArea;
