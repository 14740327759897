import React from "react";
import ServicePageArea from "../../../../components/ServiceArea/ServicePageArea";

const ServicesArea = () => {
  return (
    <>
      <section className="tp-service-area-three pt-120 pb-90">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div
                className="section-title-wrapper text-center mb-55 wow fadeInUp"
                data-wow-delay=".3s"
              >
                <h5 className="tp-section-subtitle section__sm__title common-yellow-shape mb-20 heading-color-black">
                  Our Popular Services
                </h5>
                <h2 className="tp-section-title heading-color-black">
                  Your Partner in
                  <br />
                  Home and Care Excellence
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <ServicePageArea
              service_image_num="9"
              ser_icon_img="house-cleaning"
              ser_title="Home Care"
              description="Providing support for daily living with compassionate and skilled hands. From routine tasks to personal care, we're here to make every day easier."
            ></ServicePageArea>

            <ServicePageArea
              service_image_num="10"
              ser_icon_img="cleaning"
              ser_title="Cleaning"
              description="More than just traditional cleaning, we ensure your home is a safe, clean haven. Our meticulous approach enriches your living environment."
            ></ServicePageArea>

            <ServicePageArea
              service_image_num="11"
              ser_icon_img="pot"
              ser_title="Kitchen Cooking"
              description="Crafting delicious, nutritious meals that cater to all dietary and disability requirements. We make dining a delightful experience for everyone"
            ></ServicePageArea>

            <ServicePageArea
              service_image_num="12"
              ser_icon_img="window"
              ser_title="Maintenance"
              description="Keeping your home in top condition, from minor repairs to regular upkeep. Our services are designed to maintain the comfort and safety of your living space."
            ></ServicePageArea>

            <ServicePageArea
              service_image_num="13"
              ser_icon_img="desk"
              ser_title="Administration"
              description="Managing health appointments and essential life tasks when you need it most. We offer the helping hand you need for life's administrative challenges."
            ></ServicePageArea>

            <ServicePageArea
              service_image_num="14"
              ser_icon_img="vacuum-cleaner"
              ser_title="Personal Care"
              description="Addressing all your hygiene needs with sensitivity and expertise. We ensure a clean and dignified living environment, no matter the situation."
            ></ServicePageArea>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServicesArea;
