import React from "react";
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelopeOpen } from "react-icons/fa";

const iconStyle = { fontSize: "38px" }; // Adjust icons

const ContactForm = () => {
  return (
    <>
      <section className="tp-contact-area pb-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-16">
              <div
                className="section-title-wrapper-two mb-50 wow fadeInUp"
                data-wow-delay=".2s"
              >
                <h5 className="tp-section-subtitle section__sm__title common-yellow-shape mb-20 heading-color-black">
                  Free Quote and Home Care Advice Anytime
                </h5>
                <h2 className="tp-section-title heading-color-black">
                  If you Have Any Query, Don’t Hesitate Contact us
                </h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-between">
            <div className="col-lg-4">
              <div
                className="tp-contact-info mb-40 wow fadeInUp"
                data-wow-delay=".4s"
              >
                <div className="tp-contact-info-icon">
                  <FaMapMarkerAlt className="contact_icon" style={iconStyle} />
                </div>
                <div className="tp-contact-info-text">
                  <h4 className="tp-contact-info-title mb-15">Address</h4>
                  <p>
                    Taylors Lakes 3038 <br />
                    Melbourne, Australia.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 d-flex justify-content-center">
              <div
                className="tp-contact-info mb-40 wow fadeInUp"
                data-wow-delay=".6s"
              >
                <div className="tp-contact-info-icon">
                  <FaPhoneAlt className="contact_icon" style={iconStyle} />
                </div>
                <div className="tp-contact-info-text">
                  <h4 className="tp-contact-info-title mb-15">24/7 Phone</h4>
                  <a href="tel:0426069846">0426 069 846</a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 d-flex justify-content-end">
              <div
                className="tp-contact-info mb-40 wow fadeInUp"
                data-wow-delay=".8s"
              >
                <div className="tp-contact-info-icon">
                  <FaEnvelopeOpen className="contact_icon" style={iconStyle} />
                </div>
                <div className="tp-contact-info-text">
                  <h4 className="tp-contact-info-title mb-15">Email</h4>
                  <a href="mailto:info@themepure.com">
                    VDServices.health@gmail.com
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactForm;
