import React from "react";
import { Offcanvas } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { FaFacebookF } from "react-icons/fa";
import { AiOutlineGoogle } from "react-icons/ai";
import { BsTwitter, BsInstagram } from "react-icons/bs";
import Collapsible from "react-collapsible";

const Sidebar = ({ show, handleClose }) => {
  const Home = <NavLink to="/">Home</NavLink>;
  const Services = <NavLink to="/services">Services </NavLink>;
  const ServicesDetails = <NavLink to="/servicesDetails">How We Work </NavLink>;
  const FAQ = <NavLink to="/faq">FAQ</NavLink>;
  const Contact = <NavLink to="/contact">Contact</NavLink>;
  return (
    <>
      <div className="side__bar">
        <Offcanvas show={show} onHide={handleClose} placement="end">
          <Offcanvas.Header closeButton>
            {/* <Offcanvas.Title>Offcanvas</Offcanvas.Title> */}
          </Offcanvas.Header>
          <Offcanvas.Body>
            {/*             
            <Collapsible
              trigger={Home}
              triggerTagName="div"
              triggerOpenedClassName="icon_close"
              triggerClassName="iconAdd"
              open={false}
            ></Collapsible>

            <Collapsible
              trigger={Services}
              triggerTagName="div"
              triggerOpenedClassName="icon_close"
              triggerClassName="iconAdd"
              open={false}
            ></Collapsible>

            <Collapsible
              trigger={ServicesDetails}
              triggerTagName="div"
              triggerOpenedClassName="icon_close"
              triggerClassName="iconAdd"
              open={false}
            ></Collapsible>

            <Collapsible
              trigger={FAQ}
              triggerTagName="div"
              triggerOpenedClassName="icon_close"
              triggerClassName="iconAdd"
              open={false}
            ></Collapsible> */}

            <div className="contact_nav text-white font-bold mb-10">
              <NavLink className="font-bold" to="/">
                Home
              </NavLink>
            </div>
            <div className="contact_nav text-white font-bold mb-10">
              <NavLink className="font-bold" to="/services">
                Services
              </NavLink>
            </div>
            <div className="contact_nav text-white font-bold mb-10">
              <NavLink className="font-bold" to="/servicesDetails">
                How We Work
              </NavLink>
            </div>
            {/* <div className="contact_nav text-white font-bold mb-10">
              <NavLink className="font-bold" to="/faq">
                FAQ
              </NavLink>
            </div> */}

            <div className="contact_nav text-white font-bold mb-10">
              <NavLink className="font-bold" to="/contact">
                Contact
              </NavLink>
            </div>

            <div className="fix">
              <div className="side-info">
                <div className="side-info-content">
                  <div className="tp-mobile-menu"></div>
                  <div className="contact-infos mb-30">
                    <div className="contact-list mb-30">
                      <h4>Contact Info</h4>
                      <ul>
                        <li>
                          <i className="flaticon-pin"></i>Taylors Lakes,
                          Melbourne 3038
                        </li>
                        <li>
                          <i className="flaticon-email"></i>
                          <a href="mailto:VDServices.health@gmail.com">
                            VDServices.health@gmail.com
                          </a>
                        </li>
                        <li>
                          <i className="flaticon-phone-call"></i>
                          <a href="tel:0426069846">0426 069 846</a>
                        </li>
                      </ul>
                      <div className="sidebar__menu--social">
                        <a className="text-white" href="/">
                          <i>
                            <FaFacebookF className="icon" />{" "}
                          </i>
                        </a>
                        <a className="text-white" href="/">
                          <i>
                            <BsTwitter className="icon" />{" "}
                          </i>
                        </a>
                        <a className="text-white" href="/">
                          <i>
                            <BsInstagram className="icon" />{" "}
                          </i>
                        </a>
                        <a className="text-white" href="/">
                          <i>
                            <AiOutlineGoogle className="icon" />{" "}
                          </i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  );
};

export default Sidebar;
