import React from "react";

const PricingArea = () => {
  return (
    <>
      <section className="tp-pricing-area pt-120 pb-90"></section>
    </>
  );
};

export default PricingArea;
