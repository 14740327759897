import React from 'react';
import ApointArea from '../../../Appointment/Appointment/ApointArea/ApointArea';

const TeamArea = () => {
    return (
        <>
            <ApointArea/>
        </>
    );
};

export default TeamArea;