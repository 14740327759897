import React, { useState } from "react";
import "react-modal-video/scss/modal-video.scss";
import { FaArrowAltCircleRight, FaPlay } from "react-icons/fa";
import { CgCheckO } from "react-icons/cg";
import { Link } from "react-router-dom";

const ServicesDetailsFaq = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <section className="tp-service-details-area pt-120 pb-90">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-4">
              <div className="tp-faqs-left">
                <div className="tp-faqs-left-sidebar tp-services-sidebar mb-30">
                  <h4 className="tp-faqs-left-sidebar-title">
                    Free Service Quote
                  </h4>
                  <ul>
                    <li>
                      <a href="tel:0426069846">
                        Kitchen Cooking{" "}
                        <i>
                          {" "}
                          <FaArrowAltCircleRight />{" "}
                        </i>{" "}
                      </a>
                    </li>
                    <li>
                      <a href="tel:0426069846">
                        Personal Care{" "}
                        <i>
                          {" "}
                          <FaArrowAltCircleRight />{" "}
                        </i>{" "}
                      </a>
                    </li>
                    <li>
                      <a href="tel:0426069846">
                        Home Care{" "}
                        <i>
                          {" "}
                          <FaArrowAltCircleRight />{" "}
                        </i>{" "}
                      </a>
                    </li>
                    <li>
                      <a href="tel:0426069846">
                        Administration{" "}
                        <i>
                          {" "}
                          <FaArrowAltCircleRight />{" "}
                        </i>{" "}
                      </a>
                    </li>
                    <li>
                      <a href="tel:0426069846">
                        Cleaning{" "}
                        <i>
                          {" "}
                          <FaArrowAltCircleRight />{" "}
                        </i>{" "}
                      </a>
                    </li>
                    <li>
                      <a href="tel:0426069846">
                        Maintenance{" "}
                        <i>
                          {" "}
                          <FaArrowAltCircleRight />{" "}
                        </i>{" "}
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="tp-faqs-left-img mb-30">
                  <a href="/services">
                    <img
                      src="assets/img/stockImages/detail4.jpg"
                      className="img"
                      alt="img not found"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-9 col-lg-8">
              <div className="tp-service-details">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="tp-service-details-img mb-30">
                      <img
                        src="assets/img/stockImages/detail2.jpg"
                        alt="img not found"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="tp-service-details-img mb-30">
                      <img
                        src="assets/img/stockImages/detail3.jpg"
                        alt="img not found"
                      />
                    </div>
                  </div>
                </div>
                <h2 className="tp-section-title heading-color-black pt-10 mb-20">
                  Disability Accommodation Services
                </h2>
                <p className="mb-20">
                  At VD Services, we offer Specialist Disability Accommodation
                  Services for individuals with severe functional impairments or
                  those who need exceptionally delicate and intensive care. We
                  understand the challenges faced by families of loved ones with
                  severe disabilities. That's why our approach is designed to
                  make the transition to specialist housing as seamless and
                  stress-free as possible. Our team manages the majority of
                  administrative tasks and provides the specialized support your
                  loved one needs.
                </p>
                <p className="mb-35">
                  Our commitment at VD Services extends to ensuring a smooth
                  transition for your loved one into their new home. We design
                  our housing solutions to meet the practical requirements of
                  its residents comprehensively. This involves working
                  collaboratively with you, your loved one, various service
                  providers, and liaising directly with NDIS to create an
                  effective housing solution. Keeping all relevant stakeholders
                  regularly informed is a priority for us, simplifying what can
                  often be a complex SDA arrangement process.{" "}
                </p>
                <h3 className="tp-service-details-subtitle">
                  Service Overview
                </h3>
                <p className="mb-40">
                  At VD Services, we offer an array of bespoke services designed
                  to enhance the quality of life for individuals and families.
                  Our range of services ensures that every aspect of home and
                  personal care is covered with compassion and professionalism.
                </p>

                <p className="mb-25 d-flex">
                  {" "}
                  <i>
                    {" "}
                    <CgCheckO />{" "}
                  </i>{" "}
                  Kitchen Cooking: Experience the art of culinary excellence
                  with our kitchen cooking service. We meticulously prepare
                  meals that not only cater to specific dietary and disability
                  needs but also turn each dining experience into a celebration
                  of flavors and nutrition.
                </p>
                <p className="mb-25 d-flex">
                  {" "}
                  <i>
                    {" "}
                    <CgCheckO />{" "}
                  </i>{" "}
                  Personal Care: Recognizing the critical importance of personal
                  hygiene, our team offers unwavering support in sanitary care.
                  We handle all aspects of hygiene with the utmost respect and
                  professionalism, ensuring a respectful and pristine living
                  environment.
                </p>
                <p className="mb-25 d-flex">
                  {" "}
                  <i>
                    {" "}
                    <CgCheckO />{" "}
                  </i>{" "}
                  Home Care: Our home care service is a blend of skill and
                  heart. We assist in everyday living tasks, providing a
                  nurturing touch to ensure comfort and ease. Our professionals
                  are not just caregivers; they are empathetic partners in your
                  daily journey.
                </p>
                <p className="mb-25 d-flex">
                  {" "}
                  <i>
                    {" "}
                    <CgCheckO />{" "}
                  </i>{" "}
                  Administration: Tackling life's administrative challenges, our
                  services simplify the complexities of health appointments and
                  essential tasks. We bring ease and clarity to administration,
                  freeing you to focus on what matters most.
                </p>
                <p className="mb-25 d-flex">
                  {" "}
                  <i>
                    {" "}
                    <CgCheckO />{" "}
                  </i>{" "}
                  Cleaning: Redefining the essence of cleaning, we transform
                  spaces into sanctuaries of health and relaxation. Our approach
                  goes beyond the surface, ensuring that every corner of your
                  home resonates with cleanliness and tranquility.
                </p>
                <p className="mb-25 d-flex">
                  {" "}
                  <i>
                    {" "}
                    <CgCheckO />{" "}
                  </i>{" "}
                  Maintenance: Keeping your home in pristine condition, our
                  maintenance services are thorough and thoughtful. We address
                  everything from minor fixes to regular upkeep, ensuring a safe
                  and serene living environment.
                </p>
                <h3 className="tp-service-details-subtitle mb-20">
                  Empowering Lives, Enhancing Homes
                </h3>
                <p className="mb-40">
                  At VD Services, we believe in creating a positive impact on
                  the lives we touch. Our services are designed not just to meet
                  the basic needs but to enhance the living experience of our
                  clients. Whether it's through our meticulous cleaning
                  services, thoughtful home care, or expertly prepared meals, we
                  aim to bring ease, comfort, and joy to your daily life.
                </p>
                <div className="row">
                  <div className="col-xl-6">
                    <div className="tp-service-details-img mb-30">
                      <img
                        src="assets/img/stockImages/detail1.jpg"
                        alt="img not found"
                      />
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <p className="mb-20">
                      If you're looking for a partner in home and care
                      excellence, look no further than VD Services. We are
                      dedicated to providing a service that is not just a
                      necessity but a transformative experience for you and your
                      loved ones.
                    </p>
                    <p>
                      As you embark on this journey of finding the perfect care
                      and support, let VD Services be your guiding light. We
                      don’t just offer services; we offer a promise - a promise
                      of a life filled with dignity, ease, and joy. For us, it’s
                      not just about meeting needs; it's about creating an
                      experience that touches hearts and transforms lives. Reach
                      out to VD Services today, and take the first step towards
                      a life reimagined, where care and comfort converge.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServicesDetailsFaq;
